const config = {
  gatsby: {
    pathPrefix: '/',
    siteUrl: 'https://docs.neuralfinity.com',
    gaTrackingId: null,
    trailingSlash: false,
  },
  header: {
    logo: 'https://neuralfinity.pages.dev/images/hosted/neuralfinity.svg',
    logoLink: '/',
    title:
      "Computational Magic",
    githubUrl: 'https://github.com/Neuralfinity/computational-magic_code-samples',
    helpUrl: '',
    tweetText: '',
    social: `<li>
		    <a href="https://twitter.com/neuralfinity" target="_blank" rel="noopener">
		      <div class="twitterBtn">
		        <img src='https://graphql-engine-cdn.hasura.io/learn-hasura/assets/homepage/twitter-brands-block.svg' alt={'Twitter'}/>
		      </div>
		    </a>
		  </li>`,
    links: [{ text: '', link: '' }],
    search: {
      enabled: false,
      indexName: '',
      algoliaAppId: process.env.GATSBY_ALGOLIA_APP_ID,
      algoliaSearchKey: process.env.GATSBY_ALGOLIA_SEARCH_KEY,
      algoliaAdminKey: process.env.ALGOLIA_ADMIN_KEY,
    },
  },
  sidebar: {
    forcedNavOrder: [
      '/quickstart', // add trailing slash if enabled above
      '/roadmap',
      '/computational-magic-platform', 
      '/rapid-api',
      '/magic-speech-to-text',
      '/security',
      '/contribute',
    ],
    collapsedNav: [
      '/computational-magic-platform', // add trailing slash if enabled above
      '/rapid-api',
    ],
    links: [{ text: 'Neuralfinity', link: 'https://www.neuralfinity.com' }],
    frontline: false,
    ignoreIndex: true,
  },
  siteMetadata: {
    title: 'Computational Magic Documentation | Neuralfinity',
    description: 'Documentation for Computational Magic, the simple AI GraphQL API',
    ogImage: null,
    docsLocation: 'https://github.com/Neuralfinity/computational-magic_docs/blob/main/',
    favicon: 'https://www.neuralfinity.com/favicon.png',
  },
  pwa: {
    enabled: false, // disabling this will also remove the existing service worker.
    manifest: {
      name: 'Computational Magic Documentation | Neuralfinity',
      short_name: 'Magic Docs',
      start_url: '/',
      background_color: '#6b37bf',
      theme_color: '#6b37bf',
      display: 'standalone',
      crossOrigin: 'use-credentials',
      icons: [
        {
          src: 'src/pwa-512.png',
          sizes: `512x512`,
          type: `image/png`,
        },
      ],
    },
  },
};

module.exports = config;
